









































































import Vue from "vue";
import {
  ApiException,
  WebinarAvailability,
  WebinarsClient,
  WebinarsItemPublicResponse, WebinarsItemUserResponse, WebinarsUserClient
} from "@/clients/clients";
import AppPage from "@/components/layout/page/AppPage.vue";
import WebinarDetailsTabs from "@/components/webinars/WebinarDetailsTabs.vue";
import WebinarSummary from "@/components/webinars/WebinarSummary.vue";
import WebinarPromotionalImage from "@/components/webinars/WebinarPromtionalImage.vue";
import AppGdprDialog from "@/components/dialogs/AppGdprDialog.vue";
import { mapGetters } from "vuex";
import { loginRequest } from "@/configs/authConfig";

export default Vue.extend({
  name: "WebinarPublicView",
  metaInfo() {
    const title = this.webinar?.title ?? "Thornton & Ross";
    return { title };
  },
  components: {
    AppGdprDialog,
    WebinarPromotionalImage,
    WebinarSummary,
    WebinarDetailsTabs,
    AppPage,
  },
  props: {
    id: String,
  },
  data: () => ({
    errorMessage: "",
    webinar: null as WebinarsItemUserResponse | null,
    loading: false,
    showGdprDialog: false,
  }),
  computed: {
    ...mapGetters("UserStore", ["userGdprConsent"]),
    title(): string {
      return this.webinar?.title ?? "";
    },
    webinarAvailability(): typeof WebinarAvailability {
      return WebinarAvailability;
    },
    jobCode(): string {
      return this.webinar?.jobCode ?? "";
    },
  },
  async created(): Promise<void> {
    await this.getWebinar();
  },
  methods: {
    async getWebinar(): Promise<void> {
      this.loading = true;
      this.errorMessage = "";
      const token = await this.$auth.acquireTokenSilent(loginRequest);
      const client = this.$httpClientFactory(WebinarsUserClient, token.accessToken);
      try {
        this.webinar = await client.webinarDetails(this.id);
      } catch (e) {
        this.errorMessage =
          (e as ApiException).message ?? "Unknown error occurred";
      } finally {
        this.loading = false;
      }
    },
    async enroll(): Promise<void> {
      if (!this.userGdprConsent) {
        this.showGdprDialog = true;
        return;
      }
      await this.$router.push({
        name: "WebinarEnroll",
        params: { id: this.id },
      });
    },
  },
});
